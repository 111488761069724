import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: [] }

export const sharedSlice = createSlice({
  name: 'shareds',
  initialState,
  reducers: {
    setSharedList: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { setSharedList } = sharedSlice.actions

export default sharedSlice.reducer
