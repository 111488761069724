const assets = {
  images: {
    logoDark: require('./images/logo-dark.png'),
    appIcon: require('./images/favicon.png')
  },
  colors: {
    secondary: '#292929',
    primary: '#f5f5f5'
  }
}

export default assets
